import store from '@/store'
import router from '@/router'
import merchantStoreModule from '../../../merchantStoreModule'
import commonStoreModule from '../../../commonStoreModule'

export default function merchantsJs() {
  const MERCHANT_STORE_MODULE_NAME = 'merchant'
  const COMMON_STORE_MODULE_NAME = 'common'

  // Register module
  if (!store.hasModule(MERCHANT_STORE_MODULE_NAME)) store.registerModule(MERCHANT_STORE_MODULE_NAME, merchantStoreModule)
  if (!store.hasModule(COMMON_STORE_MODULE_NAME)) store.registerModule(COMMON_STORE_MODULE_NAME, commonStoreModule)

  const fetchIndustryOptions = (success, fail) => {
    store
      .dispatch('common/fetchIndustryOptions')
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          const options = [{ value: 'All', label: 'All' }]
          Object.keys(data).forEach(key => {
            const item = {
              value: data[key].code,
              label: data[key].name,
              excludive: data[key].exclusive,
            }
            options.push(item)
          })
          success(options)
        } else {
          fail(msg)
        }
      }).catch(() => {
        fail('error')
      })
  }

  const fetchCommonOptions = (success, fail) => {
    store
      .dispatch('common/fetchCommonOptions')
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          store.commit('common/SET_CURRENCIES', data.currencies)
          store.commit('common/SET_COUNTRIES', data.countries)
          success(data)
        } else {
          fail(msg)
        }
      }).catch(() => {
        fail('error')
      })
  }

  const fetchMerchants = (success, fail) => {
    store
      .dispatch('merchant/fetchDropDownMerchants')
      .then(response => {
        const { code, msg, data } = response.data
        const merchants = []
        if (code === '10000') {
          data.forEach(item => {
            const merchant = { label: item.merchant_name, value: item.merchant_no }
            merchants.push(merchant)
          })

          success(merchants)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const filterAppList = (success, fail, params) => {
    store
      .dispatch('merchant/filterAppList', params)
      .then(response => {
        const { code, msg, data } = response.data
        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }
  const filterAppPage = (success, fail, params) => {
    store
      .dispatch('merchant/filterAppPage', params)
      .then(response => {
        const { code, msg, data } = response.data
        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }
  const fetchChannelFilters = (vm, callback) => {
    store
      .dispatch('merchant/fetchChannelFilters')
      .then(response => {
        const { code, data } = response.data

        if (code === '10000') {
          callback(data)
        }
      })
      .catch(() => {
        vm.$bvToast.toast('Error fetching Channels', {
          title: 'Error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          toaster: 'b-toaster-top-right',
          solid: true,
        })
      })
  }
  const fetchPayChannelListDetail = (vm, callback) => {
    store
      .dispatch('merchant/fetchPayChannelListDetail')
      .then(response => {
        const { code, data } = response.data

        if (code === '10000') {
          callback(data)
        }
      })
      .catch(() => {
        vm.$bvToast.toast('Error fetching Channel List Detail', {
          title: 'Error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          toaster: 'b-toaster-top-right',
          solid: true,
        })
      })
  }
  const downloadApps = (vm, params) => {
    store
      .dispatch('merchant/downloadApps', params)
      .then(response => {
        const { code } = response.data
        if (code === '10000') {
          vm.$bvToast.toast('Download Transactions Request Submitted', {
            title: 'Success',
            icon: 'AlertTriangleIcon',
            variant: 'success',
            toaster: 'b-toaster-top-right',
            solid: true,
          })

          setTimeout(() => {
            router.push({
              name: 'app-download-list',
            })
          }, 1000)
        }
      })
      .catch(() => {
        vm.$bvToast.toast('Error Download Transactions', {
          title: 'Error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          toaster: 'b-toaster-top-right',
          solid: true,
        })

        router.push({
          name: 'app-download-list',
        })
      })
  }

  const fetchDownloadList = (vm, callback) => {
    store
      .dispatch('merchant/fetchAppsDownloadList')
      .then(response => {
        const { code, data } = response.data

        if (code === '10000') {
          callback(data)
        }
      })
      .catch(() => {
        vm.$bvToast.toast('Error fetching Download List', {
          title: 'Error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          toaster: 'b-toaster-top-right',
          solid: true,
        })
      })
  }

  return {
    fetchIndustryOptions,
    fetchCommonOptions,
    fetchMerchants,
    filterAppList,
    filterAppPage,
    fetchChannelFilters,
    fetchPayChannelListDetail,
    fetchDownloadList,
    downloadApps,
  }
}
